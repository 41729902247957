.videos-page {
    text-align: center;
    height: 100vh;
}

.video-container {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.looping-video {
    width: 70%;
    height: auto;
    display: block;
    margin: 100px auto 0;
}

.heading-text {
    color: white;
}
.front-page {
    position: relative;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.logo-container {
    text-align: center;
}

.video-container {
    display: block;
    margin: 0 auto;
}

.logo {
    max-width: 60%;
    margin-top: 50px;
    opacity: 0.1;
}

@keyframes flickerAnimation {
    0%, 100% {
        opacity: 0.1;
    }

    50% {
        opacity: 0;
    }
}

.flicker {
    animation: flickerAnimation 1s infinite; /* Apply the animation to the logo */
}

.content {
    /* Style your content on top of the video background */
}

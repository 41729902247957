.full-page-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-background::poster {
    /* Add styling for the poster image here */
    /* For example, you can set a background color */
    background-color: black;
    /* You can also center the poster image */
    background-position: center;
    /* Adjust the size to cover the video element */
    background-size: cover;
}
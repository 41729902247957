a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar-logo {
    max-width: 70%; /* Set the maximum width to prevent it from exceeding the NavbarBrand */
    max-height: 100px; /* Adjust the maximum height as needed to fit your design */
    /* You can adjust the width and height values to control the logo's size */
}

.nav-link.nav-active {
    color: white; /* Change this to the desired color */
}

.navbar-toggler-icon {
    background-image: url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27><path stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/></svg>');
}

.nav-center {
    align-items: center;
}

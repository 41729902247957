.item-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust the column width as needed */
    gap: 30px; /* Adjust the gap between items */
}

.item-container {
    position: relative;
    padding-bottom: 100%; /* Create a square container (1:1 aspect ratio) */
    overflow: hidden;
    border-radius: 10px;
}

.item-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and cover the container */
    cursor: crosshair;
}

.modal-image {
    width: 100%;
    height: auto;
}

.merch-page {
    color: white;
}

.image-gallery {
    position: relative;
}

.gallery-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    color: gray;
}

.centered-text {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
}